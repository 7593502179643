const MADGICX_ORIGIN = process.env.REACT_APP_MADGICX_ORIGIN || 'https://staging-creatives-2.madgicx.com';
// const MADGICX_ORIGIN = 'http://localhost:3000';

export const MESSAGES = {
    BOOK_A_CALL: 'BOOK_A_CALL',
    UPGRADE: 'UPGRADE',
    ROUTE_TO_ADS_CREATION: 'ROUTE_TO_ADS_CREATION',
    SPARKLE_HAS_LOADED: 'SPARKLE_HAS_LOADED',
    SET_TASK_ID: 'SET_TASK_ID',
    UPSELL: 'UPSELL',
};

type MadgicxAction = keyof typeof MESSAGES;

export const sendMessageToMadgicx = (
    event: MadgicxAction,
    payload?: {
        [key: string]: string;
    },
) => {
    window.parent.postMessage({ event, payload }, MADGICX_ORIGIN);
};
